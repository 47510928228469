
























































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    isTableOfContents: Boolean,
    isNotification: Boolean,
  },
  data() {
    return {
      SUBPROCESSORS: {
        ably: {
          name: 'Ably Real-Time Ltd.',
          address: 'Techspace Shoreditch,\n25 Luke St,\nLondon EC2A 4DS',
          country: 'GB',
          url: 'https://www.ably.io/privacy',
          purpose: 'realtime',
        },
        exoscale: {
          name: 'Akenes SA',
          address: 'Boulevard de Grancy 19A,\n1006 Lausanne',
          country: 'CH',
          url: 'https://www.exoscale.com/privacy/',
          purpose: 'cloud',
        },
        aws: {
          name: 'Amazon Web Services EMEA SARL',
          address: 'Ave J.-F. Kennedy 38,\n1855 Luxembourg',
          country: 'LU',
          url: 'https://aws.amazon.com/privacy/?nc1=f_pr',
          purpose: 'cloud',
        },
        cloudflare: {
          name: 'Cloudflare, Inc.',
          address: '101 Townsend St.,\nSan Francisco,\nCA 94107',
          country: 'US',
          url: 'https://www.cloudflare.com/de-de/gdpr/introduction/',
          purpose: 'cloudflare',
        },
        digitalocean: {
          name: 'DigitalOcean, LLC',
          address: '101 Avenue of the Americas,\n10th Floor,\nNew York,\nNY 10013',
          country: 'US',
          url: 'https://www.digitalocean.com/legal/privacy-policy/',
          purpose: 'cloud',
        },
        freshworks: {
          name: 'Freshworks, Inc.',
          address: '2950 S. Delaware Street,\nSuite 201,\nSan Mateo,\nCA 94403',
          country: 'US',
          url: 'https://www.freshworks.com/privacy/',
          purpose: 'support',
        },
        sentry: {
          name: 'Functional Software, Inc.', // Sentry
          address: '132 Hawthorne St,\nSan Francisco,\nCA 94107',
          country: 'US',
          url: 'https://sentry.io/privacy/',
          purpose: 'error-reporting',
        },
        lexOffice: {
          name: 'Haufe Service Center GmbH', // LexOffice
          address: 'Munzinger Straße 9,\n79111 Freiburg',
          country: 'DE',
          url: 'https://www.lexoffice.de/datenschutz/',
          purpose: 'accounting',
        },
        heroku: {
          name: 'Heroku, Inc.',
          address: 'Salesforce Tower,\n415 Mission Street,\n3rd Floor,\nSan Francisco,\nCA 94105',
          country: 'US',
          url: 'https://www.salesforce.com/company/privacy/',
          purpose: 'cloud',
        },
        logdna: {
          name: 'LogDNA, Inc.',
          address: '274 Castro St.,\n2nd Floor,\nMountain View,\nCA 94041',
          country: 'US',
          url: 'https://logdna.com/privacy/',
          purpose: 'logs',
        },
        mailgun: {
          name: 'Mailgun Technologies, Inc.',
          address: '548 Market St. #43099 San Francisco,\nCA 94104',
          country: 'US',
          url: 'https://www.mailgun.com/privacy-policy/',
          purpose: 'email',
        },
        netlify: {
          name: 'Netlify, Inc.',
          address: '2325 3rd Street,\nSuite 215,\nSan Francisco,\nCA 94107',
          country: 'US',
          url: 'https://www.netlify.com/privacy/',
          purpose: 'web',
        },
        stripe: {
          name: 'Stripe Payments Europe, Ltd.',
          address: '1 Grand Canal Street Lower,\nGrand Canal Dock,\n8QQ4+XQ Dublin',
          country: 'IE',
          url: 'https://stripe.com/de/privacy',
          purpose: 'payments',
        },
      },
      COOKIES: {
        _cfduid: { // https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-#12345682
          functional: true,
          expiry: '30D',
        },
        __stripe_mid: {
          functional: true, // Unknown at the time of writing
          expiry: '1Y',
        },
        __stripe_sid: {
          functional: true, // Unknown at the time of writing
          expiry: '30M',
        },
      },
      LOCALSTORAGE: {
        'ab.segment': {
          functional: true,
          removable: true,
        },
        'ably-transport-preference': {
          functional: true,
          removable: true,
        },
        'auth.device_token': {
          functional: true,
          removable: true,
        },
        locale: {
          functional: true,
          removable: true,
        },
        'oauth2.access_token': {
          functional: true,
          removable: true,
        },
        'oauth2.refresh_token': {
          functional: true,
          removable: true,
        },
      },
      SESSIONSTORAGE: {
        'create.name': {
          removable: true,
        },
        'create.vanity': {
          removable: true,
        },
        'create.type': {
          removable: true,
        },
      },
      isClearing: false,
    };
  },
  methods: {
    removeLocalStorageItem(key: string): void {
      this.isClearing = true;
      window.localStorage.removeItem(key);
      window.setTimeout(() => { this.isClearing = false; }, 500);
    },
    removeSessionStorageItem(key: string): void {
      this.isClearing = true;
      window.sessionStorage.removeItem(key);
      window.setTimeout(() => { this.isClearing = false; }, 500);
    },
    clearLocalStorage(): void {
      this.isClearing = true;
      window.localStorage.clear();
      window.setTimeout(() => { this.isClearing = false; }, 500);
    },
    clearSessionStorage(): void {
      this.isClearing = true;
      window.sessionStorage.clear();
      window.setTimeout(() => { this.isClearing = false; }, 500);
    },
  },
});
